<template>
  <div>
    <div>
      <a-row>
        <a-col :xxl="7" :lg="10" :xs="24" class="px-0" :class="{ 'd-none d-md-block': $route.name === 'groupSingle' }">
          <ChatSidebar>
            <sdCards headless>
              <!-- <div class="chatbox-search">
              <sdAutoComplete :dataSource="searchData" width="100%" patterns />
            </div> -->
              <Content>
                <perfect-scrollbar
                  :options="{
                    wheelSpeed: 1,
                    swipeEasing: true,
                    suppressScrollX: true,
                  }"
                >
                  <router-view></router-view>
                </perfect-scrollbar>
              </Content>
            </sdCards>
          </ChatSidebar>
        </a-col>
        <a-col :xxl="17" :lg="14" :xs="24" class="px-0">
          <router-view name="child"></router-view>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { UL, Content, ChatSidebar } from './style';
import { Main } from '../../styled';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { SearchOutlined } from '@ant-design/icons-vue';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

const ChatApp = {
  name: 'ChatApp',
  components: { Main, UL, Content, ChatSidebar, PerfectScrollbar, SearchOutlined },
  setup() {
    const { state } = useStore();
    const match = computed(() => useRoute().matched[1]);
    const rtl = computed(() => state.themeLayout.rtlData);
    const searchData = computed(() => state.headerSearchData);
    const left = computed(() => (!rtl.value ? 'left' : 'right'));

    const me = ref('woadud@gmail.com');

    return {
      rtl,
      searchData,
      left,
      me,
      match,
    };
  },
};

export default ChatApp;
</script>
<style>
.ps {
  height: 495px;
}
.spinclass {
  margin-top: 300px;
  margin-left: 800px;
}
.ant-spin-dot-item {
  background-color: rgb(13, 179, 181) !important;
}
.d-none {
  display: none;
}
@media screen and (min-width: 991px) {
  .d-md-block {
    display: block;
  }
}
</style>
